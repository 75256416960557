import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Hero from "../sections/hero"
import Feature from "../sections/feature"
import Case from "../sections/case"
import GetStarted from "../sections/getStarted"
import LogoCloud from "../sections/logoCloud"

const HomePage = () => {
  return (
    <Layout>
      <Seo title="IBRPA" />
      <Hero />
      <Feature />
      <Case />
      <GetStarted />
      <LogoCloud />
    </Layout>
  )
}

export default HomePage
