import React from "react"
import {
  AnnotationIcon,
  CloudUploadIcon,
  MailIcon,
  SparklesIcon,
  LockClosedIcon,
  EyeIcon,
  TemplateIcon,
  PuzzleIcon,
  LightBulbIcon,
} from "@heroicons/react/outline"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import IbrpaVideo from "../assets/ibrpa.mp4"

const features = [
  {
    name: "24×7自动运行",
    icon: CloudUploadIcon,
    description: "自动登录浏览器和操作本地办公软件，模拟人工操作，自动定时",
  },
  {
    name: "跨系统数据传输",
    icon: LockClosedIcon,
    description: "无需写接口，自动实现数据的传输、下载，Excel等多种格式导出",
  },
  {
    name: "OCR识别和提取",
    icon: EyeIcon,
    description: "自动识别发票、图片、提取要素字段内容，形成格式化数据",
  },
  {
    name: "NLP自然语言理解",
    icon: AnnotationIcon,
    description: "非结构化长文本或对话意图识别，提取关键要素和知识点",
  },
  {
    name: "表格邮件处理自动化",
    icon: MailIcon,
    description: "表格Excel，邮件操作自动化，自动登入邮箱、检查、发送",
  },
  {
    name: "强大API二次开发",
    icon: PuzzleIcon,
    description: "开放丰富的API接口，支持多种语言，适合垂直行业定制解决方案",
  },
]

export const FeatureSection = () => {
  return (
    <section>
      <div className="mt-16 mx-auto max-w-7xl px-4 sm:mt-24 sm:px-6 lg:mt-32">
        <div className="lg:grid lg:grid-cols-12 lg:gap-8">
          <div className="sm:text-center md:max-w-2xl md:mx-auto lg:col-span-6 lg:text-left">
            <h1>
              <span className="block text-sm font-semibold uppercase tracking-wide text-gray-500 sm:text-base lg:text-sm xl:text-base">
                机器人简介
              </span>
              <span className="mt-1 block text-4xl tracking-tight font-extrabold sm:text-5xl xl:text-6xl">
                <span className="block text-gray-900">IBRPA机器人</span>
              </span>
            </h1>
            <p className="mt-3 text-base text-gray-500 sm:mt-5 sm:text-xl lg:text-lg xl:text-xl">
              IBRPA机器人是一个具备众多办公技能的软件机器人。它可以模拟人工操作，进行24×7无间断的工作，能准确稳定的完成海量重复、易错的工作；它可解决跨系统数据同步，无需开发数据接口，自动操作网页、软件、表格、文件处理等；它可以智能识别图片，读取非格式化数据，提取数据填写表格；它是一个多技能的数字员工，可部署在企业内部网络，助力企事业单位实现降本增效。
            </p>
          </div>
          <div className="mt-12 relative sm:max-w-lg sm:mx-auto lg:mt-0 lg:max-w-none lg:mx-0 lg:col-span-6 lg:flex lg:items-center">
            <div className="relative mx-auto w-full rounded-lg shadow-lg lg:max-w-md">
              <video controls>
                <source src={IbrpaVideo} type="video/mp4" />
              </video>
            </div>
          </div>
        </div>
      </div>

      <div className="relative bg-white py-16 sm:py-24 lg:py-32">
        <div className="mx-auto max-w-md px-4 text-center sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl">
          <h2 className="text-base font-semibold tracking-wider text-indigo-600 uppercase">
            降本增效
          </h2>
          <p className="mt-2 text-3xl font-extrabold text-gray-900 tracking-tight sm:text-4xl">
            IBRPA机器人技能
          </p>
          <p className="mt-5 max-w-prose mx-auto text-xl text-gray-500">
            无需编程、无需脚本
          </p>
          <div className="mt-12">
            <div className="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3">
              {features.map(feature => (
                <div key={feature.name} className="pt-6">
                  <div className="flow-root bg-gray-50 rounded-lg px-6 pb-8">
                    <div className="-mt-6">
                      <div>
                        <span className="inline-flex items-center justify-center p-3 bg-indigo-500 rounded-md shadow-lg">
                          <feature.icon
                            className="h-6 w-6 text-white"
                            aria-hidden="true"
                          />
                        </span>
                      </div>
                      <h3 className="mt-8 text-lg font-medium text-gray-900 tracking-tight">
                        {feature.name}
                      </h3>
                      <p className="mt-5 text-base text-gray-500">
                        {feature.description}
                      </p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      <div className="relative bg-white pt-16 pb-16 overflow-hidden">
        <div className="mb-24">
          <div className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24">
            <div className="px-4 max-w-xl mx-auto sm:px-6 lg:py-16 lg:max-w-none lg:mx-0 lg:px-0 lg:col-start-2">
              <div>
                <div>
                  <span className="h-12 w-12 rounded-md flex items-center justify-center bg-indigo-600">
                    <LightBulbIcon
                      className="h-6 w-6 text-white"
                      aria-hidden="true"
                    />
                  </span>
                </div>
                <div className="mt-6">
                  <h2 className="text-3xl font-extrabold tracking-tight text-gray-900">
                    基于Petri网，自动优化业务流程
                  </h2>
                  <p className="mt-4 text-lg text-gray-500">
                    业务过程建模是一个需要很多经验、且复杂而耗时的技术工作，过程挖掘技术能够从已有的工作流系统的日志中挖掘出过程模型，辅助设计者进行过程建模。过程挖掘的结果用Petri-net来表达，保证了流程可执行，大大提升了准确性。
                  </p>
                  <div className="mt-6">
                    <Link
                      to="/about#petri"
                      className="inline-flex px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700"
                    >
                      了解更多的Petri网创新
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-12 sm:mt-16 lg:mt-0 lg:col-start-1">
              <div className="px-4 sm:px-6 lg:px-8 lg:m-0 lg:relative lg:w-full justify-items-center">
                <StaticImage
                  layout="constrained"
                  src="../images/Petri网.png"
                  alt="Petri网创新"
                />
              </div>
            </div>
          </div>
        </div>

        <div className="relative">
          <div className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24">
            <div className="px-4 max-w-xl mx-auto sm:px-6 lg:py-6 lg:max-w-none lg:mx-0 lg:px-0">
              <div>
                <div>
                  <span className="h-12 w-12 rounded-md flex items-center justify-center bg-indigo-600">
                    <TemplateIcon
                      className="h-6 w-6 text-white"
                      aria-hidden="true"
                    />
                  </span>
                </div>
                <div className="mt-6">
                  <h2 className="text-3xl font-extrabold tracking-tight text-gray-900">
                    丰富场景模版，快速实施
                  </h2>
                  <p className="mt-4 text-lg text-gray-500">
                    与合作伙伴的长期打磨，沉淀了大量组件级、应用级模版，无论是自行构建还是直接自动化流程，都能开箱即用快速实施。强大的产品矩阵，构建了大量成熟行业智能自动化解决方案。
                  </p>
                </div>
              </div>
              <div className="mt-8 border-t border-gray-200 pt-6">
                <blockquote>
                  <div>
                    <p className="text-base text-gray-500">
                      &ldquo;点个按钮就自动完成Excel校正和出口电商申报，省了半个小时。&rdquo;
                    </p>
                  </div>
                  <footer className="mt-3">
                    <div className="flex items-center space-x-3">
                      <div className="flex-shrink-0">
                        <StaticImage
                          className="h-6 w-6 rounded-full"
                          src="../images/MissLi.jpg"
                          alt=""
                        />
                      </div>
                      <div className="text-base font-medium text-gray-700">
                        李女士, 跨境电商企业主
                      </div>
                    </div>
                  </footer>
                </blockquote>
              </div>
            </div>
            <div className="mt-12 sm:mt-16 lg:mt-0">
              <div className="px-4 sm:px-6 lg:px-8 lg:m-0 lg:relative lg:h-full">
                <StaticImage
                  layout="constrained"
                  src="../images/丰富场景模版.png"
                  alt="丰富场景模版"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="mt-24">
          <div className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24">
            <div className="px-4 max-w-xl mx-auto sm:px-6 lg:py-16 lg:max-w-none lg:mx-0 lg:px-0 lg:col-start-2">
              <div>
                <div>
                  <span className="h-12 w-12 rounded-md flex items-center justify-center bg-indigo-600">
                    <SparklesIcon
                      className="h-6 w-6 text-white"
                      aria-hidden="true"
                    />
                  </span>
                </div>
                <div className="mt-6">
                  <h2 className="text-3xl font-extrabold tracking-tight text-gray-900">
                    操作更省事，工具更好用
                  </h2>
                  <p className="mt-4 text-lg text-gray-500">
                    可视化，拖拉拽，业务人员即可构建自动化流程，AI智能能力简单可取、RPA自动化人人可用。
                  </p>
                </div>
              </div>
            </div>
            <div className="mt-12 sm:mt-16 lg:mt-0 lg:col-start-1">
              <div className="px-4 sm:px-6 lg:px-8 lg:m-0 lg:relative lg:h-full">
                <StaticImage
                  layout="constrained"
                  src="../images/操作更省事.png"
                  alt="操作更省事"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default FeatureSection
