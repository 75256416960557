import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

export const CaseSection = () => {
  const data = useStaticQuery(graphql`
    {
      mdx(slug: { eq: "case/cover" }) {
        frontmatter {
          cases {
            title
            href
            category
            description
            image {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
      }
    }
  `)
  const cases = data.mdx.frontmatter.cases
  return (
    <section>
      <div className="relative bg-gray-50 pt-16 pb-20 px-4 sm:px-6 lg:pt-24 lg:pb-28 lg:px-8">
        <div className="absolute inset-0">
          <div className="bg-white h-1/3 sm:h-2/3" />
        </div>
        <div className="relative max-w-7xl mx-auto">
          <div className="text-center">
            <h2 className="text-3xl tracking-tight font-extrabold text-gray-900 sm:text-4xl">
              客户案例
            </h2>
            <p className="mt-3 max-w-2xl mx-auto text-xl text-gray-500 sm:mt-4"></p>
          </div>
          <div className="mt-12 max-w-lg mx-auto grid gap-5 lg:grid-cols-3 lg:max-w-none">
            {cases.map(c => (
              <div
                key={c.title}
                className="flex flex-col rounded-lg shadow-lg overflow-hidden"
              >
                <div className="flex-shrink-0">
                  <GatsbyImage
                    className="h-48 w-full object-cover"
                    image={getImage(c.image)}
                    alt=""
                  />
                </div>
                <div className="flex-1 bg-white p-6 flex flex-col justify-between">
                  <div className="flex-1">
                    <p className="text-sm font-medium text-indigo-600">
                      <Link to={c.href} className="hover:underline">
                        {c.category}
                      </Link>
                    </p>
                    <Link to={c.href} className="block mt-2">
                      <p className="text-xl font-semibold text-gray-900">
                        {c.title}
                      </p>
                      <p className="mt-3 text-base text-gray-500">
                        {c.description}
                      </p>
                    </Link>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  )
}

export default CaseSection
