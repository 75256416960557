import React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { useStaticQuery, graphql } from "gatsby"

export const LogoCloudSection = () => {
  const data = useStaticQuery(graphql`
    {
      mdx(slug: { eq: "logo/" }) {
        frontmatter {
          partners {
            company
            image {
              id
              childImageSharp {
                gatsbyImageData
              }
            }
          }
          clients {
            company
            image {
              id
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
      }
    }
  `)
  const partners = data.mdx.frontmatter.partners
  const clients = data.mdx.frontmatter.clients
  return (
    <section>
      <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
        <p className="text-center text-base font-semibold uppercase text-gray-600 tracking-wider">
          合作伙伴
        </p>
        <div className="mt-6 grid grid-cols-2 gap-0.5 md:grid-cols-4 lg:mt-8">
          {partners.map(partner => (
            <div
              key={partner.image.id}
              className="col-span-1 flex justify-center py-4 px-8"
            >
              <GatsbyImage
                className="max-h-16"
                image={getImage(partner.image)}
                alt={partner.company}
              />
            </div>
          ))}
        </div>
      </div>

      <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
        <p className="text-center text-base font-semibold uppercase text-gray-600 tracking-wider">
          企业用户
        </p>
        <div className="mt-6 grid grid-cols-2 gap-0.5 md:grid-cols-4 lg:mt-8">
          {clients.map(client => (
            <div
              key={client.image.id}
              className="col-span-1 flex justify-center py-4 px-8"
            >
              <GatsbyImage
                className="max-h-16"
                image={getImage(client.image)}
                alt={client.company}
              />
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}

export default LogoCloudSection
